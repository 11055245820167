<template>
  <v-row class="ma-0">
    <v-col cols="12">

      <v-card class="ma-0" elevation="10">
        <v-card-title class="ma-0" >

           <v-toolbar tile dense dark color="primary">
            Kardex del producto.
          </v-toolbar>

          <v-spacer></v-spacer>

          <v-btn color="success" 
            class="ma-2"
            small 
            @click="consultar()">
            Consultar Todos
          </v-btn>
          
          <v-btn color="black" 
            small dark
            @click="kardex">
            Kardex          
          </v-btn>

        </v-card-title>
  
        <v-card-text>
          <v-row>
           

            <v-col cols="3">  
                <!-- Almacen {{selectAlm.numalm}} -->
              <v-select 
                v-model="selectAlm"
                :hint="` ${selectAlm.numalm}`"
                :items="almacen"
                item-text="nomalm"
                item-value="numalm" 
                label="Selecionar El almacen"
                persistent-hint
                return-object  
                @change="consultar"
              ></v-select>
            </v-col>  

            <v-col class="ma-0 pa-0" cols="3">
             <Leearts></Leearts>  
            </v-col>

            <v-spacer></v-spacer>
            <!-- FECHA INICIAL -->
            <v-col cols="12" xl="2" lg="2" md="3" sm="4" xs="4">
              <v-menu

                v-model="menu1"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="date1"
                    label="Fecha Inicial"
                    prepend-icon="mdi-calendar-arrow-right"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                
                <v-date-picker 
                  locale="es"
                  v-model="date1" 
                  @input="menu1 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
        
            <!-- FECHA FINAL -->
             <v-col cols="12" xl="2" lg="2" md="3" sm="4" xs="4">
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="date2"
                    label="Fecha Final"
                    prepend-icon="mdi-calendar-arrow-left"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker 
                  locale="es"
                  v-model="date2" 
                  @input="menu2 = false"></v-date-picker>
              </v-menu>
            </v-col>
 
          </v-row>  
        </v-card-text>  

        <!-- DATATABLE -->
        <v-col cols="12">
          <v-data-table
            :loading="loading" 
            loading-text="Cargando..."
            :headers="headers"
            :items="coninv"
            :items-per-page="longitud"
            class="elevation-1"
            fixed-header
            height="350"
            :search="search"
            hide-default-footer
            dense
          >
            <template v-slot:item="{ item }" >
              <tr v-on:dblclick="edit(item)">
                <td align= 'end'>{{ item.tipodoc}}</td>
                <td align= 'end'>{{ item.numdoc}}</td>
                <td align= 'end'>{{ item.numpar}}</td>
                <td>{{ item.numcli}}</td>
                <td>{{ item.fecha}}</td>
                <td>{{ item.numalm}}</td>

                <td>{{ item.numart }}</td>
                <td>{{ item.descrip }}</td>

                <td align= 'end'>{{ item.cant}}</td>
                <td align= 'end'>{{ item.disp}}</td>

                <td align= 'end'>{{ item.lote}}</td>
                <td align= 'end'>{{ item.nomubi}}</td>

                <td align= 'end'>{{ item.tok}}</td>
                <td align= 'end'>{{ item.tu}}</td>
                <td align= 'end'>{{ item.rdp}}</td>
                <td align= 'end'>{{ item.obs}}</td>



               </tr>
           </template>
          </v-data-table>

        </v-col>

      </v-card> 
    </v-col>  
  </v-row>  
</template>

<script>
  import {mapGetters, mapActions} from 'vuex'
  import Leearts from '@/components/leearticulo'

  var accounting = require("accounting");
  // var base64 = require('base-64');
  // import excelMixin from '@/mixins/excelMixin.js';
  // import jsPDF from 'jspdf'
  // import autoTable from 'jspdf-autotable';
  
  export default {
    components: {
      Leearts, 
    },

    data () {
      return {
        //ALAMACENES
        almacenes: [],
        almacen: [],
        numalm: '',
        selectAlm: {nomalm:'', numalm:''},
        

        //FECHAS
        date1: new Date().toISOString().substr(0, 10),
        date2: new Date().toISOString().substr(0, 10),
        
        menu1: false,
        menu2: false,

        // SELECTOR TIPODOC
        tipodocs: ['Entradas', 'Salidas'],
        tipodoc: 'Cotización',
        e1:'Pedidos',
        loading: true,
        page: 1,
        pageCount: 0,
        itemsPerPage: 10,
        search: '',
        longitud: 5,
       
        headers: [
          { text: 'Tipo', align: 'end', value: 'tipodoc' },  
          { text: 'Folio', align: 'end', value: 'numdoc' },
          { text: 'Par', align: 'end', value: 'numpar' },

          { text: 'Cliente', align: 'star', value: 'numcli' },
          { text: 'Fecha', align: 'star', value: 'fecha' },
          { text: 'Alm', align: 'star', value: 'numalm' },

          { text: 'Articulo', align: 'star', value: 'numart' },
          { text: 'Descripción', align: 'star', value: 'descrip' },
          
          { text: 'Cantidad', align: 'end', value: 'cant' },
          { text: 'Disponible', align: 'end', value: 'disp' },

          { text: 'Lote', align: 'end', value: 'lote' },
          { text: 'Ubicación', align: 'end', value: 'nomubi' },

          { text: 'TO', align: 'end', value: 'tok' },
          { text: 'TU', align: 'end', value: 'tu' },

          { text: 'RDP', align: 'end', value: 'rdp' },
          { text: 'OBS', align: 'end', value: 'obs' },


        ],

        Docum: [],
        coninv: []
      }
    },

    computed:{
      ...mapGetters('leeart',['getleeart'])
    },

    created () {
      //Recibir parametro de tipo de Documento a Abrir. 
       console.log("Params",this.$route.params)
      
      // Fecha inicial Primero de mes.
      let date0 = new Date()
      let mes = date0.getMonth()
      let anio = date0.getFullYear()
            
      // console.log(mes, anio)
      this.date1 = new Date(anio, mes, 1).toISOString().substr(0, 10)
      // this.consultar()

        //consultar almacenes
      this.loading= true
      this.$http.get('v2/almacen.list').then( response=>{
          console.log("almacenes",response)
          this.loading = false

          // if response.result = null 
          if (response.data.result !== null ){
            response.data.result.forEach(element =>{
              // console.log(element.numalm)
              this.almacenes.push (element.numalm)
            })
            this.almacen = response.data.result
            this.selectAlm = response.data.result[0]

            console.log("nomalm", response.data.result[0].nomalm)
            // this.longitud = response.data.result.length
          
          } else { 
            console.log("SIN RESULTADOS")
            this.arts = []
            this.snackbar = true
            this.text = "No se encontraron almacenes"
            this.color = "info"
          }

        }).catch(error=>{
          this.almacenes= []
          this.loading = false
          console.log(error)
        })
      

    },

    methods:{
      ...mapActions('documento',['addDocumento']),
      ...mapActions('leeart', ['setleeart']),

      // FORMATO PDF
      // crearPDF() {
      //   var columns = [
      //       { title: 'ID',        dataKey: 'Numdoc' },
      //       { title: 'Fecha' ,    dataKey: 'Fecha' },
      //       { title: 'Importe',   dataKey: 'importe' },
      //       { title: 'Descuento', dataKey: 'descuento' },
      //       { title: 'Iva',       dataKey: 'impuesto1' },
      //       { title: 'Total',     dataKey: 'total' },
      //       { title: 'Divisa',    dataKey: 'divisa' },
      //       { title: 'Tc',        dataKey: 'Tc' },
      //       { title: 'Estatus',   dataKey: 'Status' },
      //   ];

      //   var doc = new jsPDF('p','pt');
      //   doc.autoTable(columns, this.Docum, {
      //       styles: {minCellWidth: 50, halign: 'left'},
      //       columnStyles: {
      //         0: {cellWidth: 'auto'},
      //         1: {cellWidth: 'auto'},
      //         2: {cellWidth: 'auto'},
      //         3: {cellWidth: 'auto'},
      //         4: {cellWidth: 'auto'},
              
      //         },
      //       margin: {top: 60},
      //       didDrawPage: function(data) {
      //         doc.text("Consulta de Documentos", 40, 30);
      //     }
      //   });
      //   doc.save('Documentos.pdf');
      // },


      consultar(){

        // Obtener gerLeeArt 
        console.log("getleeart numart", this.getleeart.numart)

        this.coninv = []
        // console.log(this.tipodoc)
        var payload = {
          numart: this.getleeart.numart, 
          numalm: this.selectAlm.numalm, 
          tipodoc:'',
          numcli: ''
        }
        
        // if(this.tipodoc == 'Pedido'){
        //   payload.tipodoc = ' P'
        // }else{
        //   payload.tipodoc = ' Q'
        // }

        this.loading = true

        // if(this.getModo == 'vendedor'){
        //   payload.numvend = this.getInfoVend.Numvend
        // }else{
        //   payload.numcli = this.getdatosUsuario.numcli
        // }

        console.log("payload",payload)
        
        this.$http.post('v2/kardex.list.alm',payload).then(response => {
           console.log("conivn", response)
          //Calcular el Total. 
          if (response.data.result !== null) {
            this.coninv = response.data.result
            this.coninv.forEach ( element => {
              element.cant = accounting.unformat(element.cant)
              element.disp = accounting.unformat(element.disp)
              element.cant = accounting.formatNumber(element.cant,2)
              element.disp = accounting.formatNumber(element.disp,2)
            })
            this.longitud= this.coninv.length
            console.log("conivn", this.coninv)

          }

        }).catch(function(error){
          console.log(error)
        }).finally(()  => this.loading = false) 

      },


      kardex (){
        this.coninv = []
        this.loading = true


        let payload = {
          numart: this.getleeart.numart, 
          numalm: this.selectAlm.numalm, 
          tipodoc:'',
          numcli: ''
        }
        
        console.log("payload kardex Param", payload)
        
        this.$http.post('v2/kardex', payload).then(response => {
           console.log("kardex response", response)
          //Calcular el Total. 
          if (response.data.result.length > 0 ) {
            this.coninv = response.data.result
            this.coninv.forEach ( element => {
              element.cant = accounting.unformat(element.cant)
              element.disp = accounting.unformat(element.disp)
              element.cant = accounting.formatNumber(element.cant,2)
              element.disp = accounting.formatNumber(element.disp,2)
            })
            this.longitud= this.coninv.length
            console.log("conivn", this.coninv)

          }

        }).catch(function(error){
          console.log(error)
        }).finally(()  => this.loading = false) 

      },





      edit(tipodoc){
        console.log(tipodoc)
        // this.addDocumento(tipodoc)
        // var encodedData = base64.encode(tipodoc.Iddocum);
        // var doc = {doc: encodedData}
        // this.$router.push({name:'documento', params: {info: doc, origen: 'cotizacion'}}).catch(err => {console.log(err)})
      },

      getColor (status) {
        if (status == 0) return 'red'
          else if (status == 9) return 'green'
        else return 'green'
      },

      exportar(){
        let xls = {
          data:      this.Docum,
          tHeaders : ['ID doc', 'Nombre', 'Tipo', 'Fecha', 'Total', 'Divisa', 'Estatus',],
          filterVal: ['Numdoc', 'nomcli', 'Tipodoc', 'Fecha', 'importe', 'divisa', 'Status'],
          nomfile :  "Consulta de Documentos"
        }
        // console.log(xls)
         this.exportarXls(xls)
      },


    }
  };
</script>