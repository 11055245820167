<template>
  <v-container class="ma-0 pa-0">
  
    <v-row class="ma-0 pa-0">
      <v-col class="ma-0 pa-0" cols="12" xm="12">
  
          <!-- prepend-inner-icon="mdi-magnify" -->
        <v-text-field
          append-icon="mdi-magnify"
          
          @click:append   ="buscarart"
          v-on:keyup.enter="buscarart"
          @click:clear    ="limpiar"
          label="Buscar Artículos"

          prepend-inner-icon="mdi-barcode"
          @click:prepend-inner="openEscaner"

          v-model="buscando"
          clearable
          :loading="loading"
          v-on:keyup.113 = "buscarart"
        ></v-text-field>
      
       <p class="mx-2 my-0"> {{ descrip }}   </p>
      </v-col>
    </v-row>

    <v-row class="ma-0 pa-0">

      <v-col class="ma-0 pa-0" cols="12" v-if="arts.length>0" xm="12">

        <p>{{arts.length}} resultados</p>

          <!-- :sortable="sortable" -->
          <v-data-table  v-if ="arts.length>0"
            :headers="headers"
            :items="arts"
            ref="tabla"
            @click:row="seleccionar" item-key="numart"
            hide-default-footer
            class="elevation-7"
            :items-per-page="longitud"
            fixed-header
            height="330"
            dense
            :loading="loading" 
            hide-default-header
          >
             <template v-slot:item="{ item }" >
              <tr v-on:click.once="seleccionar(item)">
                <td>{{ item.numart}}</td>
                <td>{{ item.descrip }}</td>
              </tr>
           </template>
        </v-data-table>
        
       <!-- oArt: {{ oArt }} -->
    </v-col>

     <v-snackbar
          v-model="snackbar" :timeout="3000" top
          :color="color" 
          >
          {{text}} 
          <v-btn
            color="white"
            text
            @click="snackbar = false"
          >
            Cerrrar
          </v-btn>
        </v-snackbar>
  </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters} from 'vuex'
// import store from '@/store'

// var db = null;
// document.addEventListener('deviceready', function() {
//   db = window.sqlitePlugin.openDatabase({
//     name: 'md3.db',
//     location: 'default',
//   })
// });

  export default {
    data: () => ({
      buscando:'',
      longitud:30,
      loading: false,
      arts:[],
      descrip:'',
      numart:'',
      //SNACKBAR
      text    : '',
      snackbar: false,
      color   : '',
      
      oArt: null,
      sortable:false,
      headers: [
          { text: 'Clave', value: 'numart' },
          { text: 'Descripción', value: 'descrip' }
        ]
    }),

     watch:{
      getArt: function(){
        // console.log("leeart wath", this.getArt.numart)

        if(this.getArt.numart == undefined){
           this.buscando = ''
          // this.numart = ''
          this.descrip=''
        }
      }
    },


    computed:{
      // ...mapGetters('carrito',['getCarrito','getTC']),
      ...mapGetters('leeart',['getleeart']),
      // ...mapGetters('Login', ['getCliente']),
      
    },


    methods:{
      // ...mapState('carrito',['carrito']),
      // ...mapActions('carrito',['carritoadd','traerTC']),
      // ...mapActions('articulos',['verArt', 'limpiarart']),
      ...mapActions('leeart', ['setleeart']),
      // ...mapState('carrito',['carrito']),
      
        openEscaner(){
          //BUSCAR SCANER QUE HABRA EN BROWSER
            // var me = this
            // cordova.plugins.barcodeScanner.scan(function (result) {          
            //    // alert("We got a barcode\n" +
            //    //      "Result: " + result.text + "\n" +
            //    //      "Format: " + result.format + "\n" +
            //    //      "Cancelled: " + result.cancelled);
            //   me.buscando = result.text
            //   console.log(result.text)              
            //   me.buscarart()
            //   me.seleccionar()  
            // },function (error) {
            //     alert("Fallo el scaner: " + error);
            // })
        },  

      buscarart() {
        if (this.buscando == null ) return

        let payload = {
          "BuscAart": this.buscando.trim()
        }
 
        this.$http.post('v2/product.find', payload).then(response=>{
          console.log(response)
          this.loading = false

          // if response.result = null 
          if (response.data.result !== null ){
            this.arts = response.data.result
            this.longitud = response.data.result.length
          
          } else { 
            console.log("SIN RESULTADOS")
            this.arts = []
            this.snackbar = true
            this.text = "No se encontraron productos"
            this.color = "info"
          }

        }).catch(error=>{
          this.arts= []
          this.loading = false
          console.log(error)})
      },



      limpiar(){
          this.arts=[]
          this.arts=''
          this.numart =''
          this.descrip=''
          var nuevo = {
            numart:'',
            descrip:''
          }
          this.setleeart(nuevo)
      },

      seleccionar(oArt){
        
        // console.log("PrecioUtil al seleccionar",this.getCliente.precioutil)
        console.log("SELECCIOANAR ",oArt)
        this.oArt = oArt
        this.descrip = oArt.descrip
        // this.numart = oArt.numart
        this.arts = []
        // this.buscando = oArt.descrip
        this.buscando = oArt.numart
        
        // oArt.precioutil = this.getCliente.precioutil
        console.log("Articulo a agregar SELECCIOANAR", oArt)

        this.setleeart(oArt)
      },
      
    }
  }

</script>